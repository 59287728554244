import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const InvoicesApiService = {
  async getInvoices({
    accountId, orderId, to, from, number, pageNum = 1, pageSize = 10,
  }) {
    try {
      const { data } = await HTTP.get('invoices', {
        params: {
          accountId,
          pageSize,
          pageNum,
          orderId,
          number,
          from,
          to,
        },
      });

      return data.items || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getInvoiceFileURL({ invoiceId, format }) {
    try {
      const { data } = await HTTP.get(`invoices/${invoiceId}/download`, {
        params: {
          format,
        },
      });

      return data.url || '';
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
